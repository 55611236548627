import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Image from 'gatsby-image';
import React from 'react';
import ReactMarkdown from 'react-markdown';
/** @jsx jsx */
import {
  Box,
  Divider,
  Flex,
  Grid,
  Heading,
  Text,
  ThemeProvider,
  jsx,
} from 'theme-ui';

import { EventDate, SEO } from 'components';

function Events() {
  const data = useStaticQuery(graphql`
    query AllEvents {
      events: allContentfulEvent {
        edges {
          node {
            id
            title
            content {
              content
            }
            eventStart
            eventEnd
            image {
              sizes(maxWidth: 1280) {
                ...GatsbyContentfulSizes
              }
            }
          }
        }
      }
      guitar: file(relativePath: { eq: "guitar@2x.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1680) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const events = data?.events?.edges;

  return (
    <>
      <SEO title="Events" />
      <BackgroundImage
        fluid={data.guitar.childImageSharp.fluid}
        backgroundColor={'#040e18'}
        sx={{ py: ['calc(30% - 45px)', 'calc(15% - 45px)'], px: 'm' }}
      >
        <Heading
          variant="heading.xxl"
          sx={{ color: 'white.100', textAlign: 'center', mb: ['s'] }}
        >
          Our programme
        </Heading>
        <Text
          variant="stnd.s"
          sx={{
            maxWidth: 640,
            px: 'm',
            mx: 'auto',
            color: 'white.100',
            textAlign: 'center',
          }}
        >
          Entertainment from the very best live music to quiz nights and
          community orientated events.
        </Text>
      </BackgroundImage>
      <Grid
        variant="boxes.container"
        sx={{
          px: ['m', 'l'],
          pt: ['l', 'xl', 'xxl', 'xxxl'],
          pb: ['xl', 'xxl', 'xxxl'],
        }}
        gap={['l', 'xl', 'xxl']}
      >
        {events.map((event) => (
          <Grid
            columns={[1, null, '320px auto']}
            gap={['l', null, null, 'xxl']}
            sx={{ alignItems: 'center' }}
          >
            <Image
              fluid={event?.node?.image?.sizes}
              style={{ display: 'block' }}
            />
            <Box>
              <Box variant="stnd.xxs" mb="s" sx={{ color: 'green.dark' }}>
                <EventDate
                  eventStart={event?.node?.eventStart}
                  eventEnd={event?.node?.eventEnd}
                />
              </Box>

              <Heading variant="heading.l" sx={{ color: 'orange.dark' }}>
                {event.node.title}
              </Heading>
              <Divider />

              <Box>
                <Box
                  sx={{
                    '& p:last-of-type': {
                      marginBottom: 0,
                    },
                    maxWidth: 640,
                  }}
                >
                  <ReactMarkdown source={event?.node?.content?.content} />
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

export default Events;
